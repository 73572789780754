import React from 'react';

const AlertsIcon: React.FC<{ height?: string; width?: string }> = ({
  height = 32,
  width = 40,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M27.141 26.7C27.141 28.095 26 29.235 24.606 29.235H4.324C2.93 29.235 1.789 28.094 1.789 26.7V11.49C1.789 10.095 2.93 8.95502 4.324 8.95502H24.606C26.001 8.95502 27.141 10.096 27.141 11.49V26.7Z"
        fill="white"
      />
      <path
        d="M24.605 30.024H4.324C2.491 30.024 1 28.534 1 26.7V11.49C1 9.65702 2.491 8.16602 4.324 8.16602H24.606C26.44 8.16602 27.93 9.65702 27.93 11.49V26.7C27.93 28.533 26.439 30.024 24.605 30.024ZM4.324 9.74302C3.361 9.74302 2.578 10.526 2.578 11.489V26.699C2.578 27.662 3.361 28.445 4.324 28.445H24.606C25.569 28.445 26.352 27.662 26.352 26.699V11.489C26.352 10.526 25.569 9.74302 24.606 9.74302H4.324Z"
        fill="black"
      />
      <path
        d="M14.464 19.883C14.291 19.883 14.117 19.826 13.972 19.711L2.125 10.234C1.785 9.96199 1.73 9.46499 2.002 9.12499C2.274 8.78499 2.771 8.72899 3.111 9.00199L14.957 18.479C15.297 18.75 15.353 19.247 15.08 19.588C14.925 19.782 14.695 19.883 14.464 19.883Z"
        fill="black"
      />
      <path
        d="M14.465 19.883C14.233 19.883 14.004 19.781 13.848 19.588C13.576 19.246 13.631 18.75 13.971 18.479L25.818 9.002C26.158 8.729 26.655 8.784 26.927 9.125C27.198 9.465 27.144 9.962 26.804 10.234L14.957 19.712C14.812 19.826 14.638 19.883 14.465 19.883Z"
        fill="black"
      />
      <path
        d="M18.795 16.409C17.195 16.409 16.542 15.277 17.341 13.892L23.152 3.82599C23.951 2.44199 25.259 2.44199 26.058 3.82599L31.87 13.892C32.67 15.276 32.016 16.409 30.418 16.409H18.795Z"
        fill="#FFC900"
      />
      <path
        d="M30.418 17.198H18.795C17.743 17.198 16.901 16.786 16.485 16.065C16.069 15.344 16.132 14.409 16.658 13.499L22.469 3.432C22.995 2.522 23.774 2 24.606 2C25.438 2 26.217 2.522 26.743 3.432L32.555 13.498C33.08 14.408 33.144 15.343 32.728 16.064C32.311 16.785 31.469 17.198 30.418 17.198ZM24.605 3.578C24.356 3.578 24.068 3.818 23.835 4.222L18.023 14.287C17.791 14.69 17.726 15.06 17.85 15.275C17.975 15.491 18.328 15.62 18.793 15.62H30.416C30.881 15.62 31.232 15.491 31.357 15.275C31.482 15.06 31.418 14.69 31.185 14.287L25.375 4.221C25.143 3.818 24.855 3.578 24.605 3.578Z"
        fill="black"
      />
      <path
        d="M24.605 12.076C24.169 12.076 23.816 11.722 23.816 11.287V7.61399C23.816 7.17799 24.17 6.82599 24.605 6.82599C25.04 6.82599 25.394 7.17899 25.394 7.61399V11.287C25.395 11.722 25.041 12.076 24.605 12.076Z"
        fill="black"
      />
      <path
        d="M24.605 14.239C24.398 14.239 24.195 14.155 24.048 14.008C23.901 13.862 23.817 13.658 23.817 13.45C23.817 13.242 23.901 13.039 24.048 12.892C24.194 12.745 24.398 12.661 24.605 12.661C24.812 12.661 25.017 12.745 25.164 12.892C25.311 13.039 25.394 13.242 25.394 13.45C25.394 13.658 25.31 13.862 25.164 14.008C25.017 14.155 24.812 14.239 24.605 14.239Z"
        fill="black"
      />
    </svg>
  );
};

export default AlertsIcon;
