import React from 'react';

const InfectionIcon: React.FC<{ height?: string; width?: string }> = ({
  height = 32,
  width = 40,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_217_12737)">
        <rect width="32" height="32" fill="white" />
        <path
          d="M9.28822 2.93766C9.87427 2.3516 10.6691 2.02236 11.4979 2.02236L20.479 2.02236C21.3078 2.02236 22.1026 2.3516 22.6887 2.93765L29.0392 9.28821C29.6253 9.87426 29.9545 10.6691 29.9545 11.4979L29.9545 20.479C29.9545 21.3078 29.6253 22.1026 29.0392 22.6887L22.6887 29.0392C22.1026 29.6253 21.3078 29.9545 20.479 29.9545L11.4979 29.9545C10.6691 29.9545 9.87427 29.6253 9.28822 29.0392L2.93767 22.6887C2.35162 22.1026 2.02238 21.3078 2.02237 20.479L2.02237 11.4979C2.02238 10.6691 2.35162 9.87426 2.93767 9.28821L9.28822 2.93766Z"
          stroke="black"
          stroke-width="1.75"
        />
        <path
          d="M16.0006 11.9522C15.5331 11.9522 15.1547 11.5726 15.1547 11.1063V7.84591C15.1547 7.37846 15.5331 7 16.0006 7C16.468 7 16.8465 7.37953 16.8465 7.84591V11.1063C16.8465 11.5726 16.467 11.9522 16.0006 11.9522Z"
          fill="#D9002C"
        />
        <path
          d="M16.9976 8.69182H15.0045C14.5371 8.69182 14.1586 8.31229 14.1586 7.84591C14.1586 7.37739 14.5382 7 15.0045 7H16.9976C17.4651 7 17.8435 7.37846 17.8435 7.84591C17.8425 8.31229 17.464 8.69182 16.9976 8.69182Z"
          fill="#D9002C"
        />
        <path
          d="M16.0006 25C15.5331 25 15.1547 24.6205 15.1547 24.1541V20.8937C15.1547 20.4263 15.5331 20.0478 16.0006 20.0478C16.468 20.0478 16.8465 20.4274 16.8465 20.8937V24.1541C16.8465 24.6205 16.4669 25 16.0006 25Z"
          fill="#D9002C"
        />
        <path
          d="M16.9976 25H15.0045C14.5371 25 14.1586 24.6204 14.1586 24.154C14.1586 23.6877 14.5371 23.3081 15.0045 23.3081H16.9976C17.4651 23.3081 17.8435 23.6877 17.8435 24.154C17.8435 24.6204 17.464 25 16.9976 25Z"
          fill="#D9002C"
        />
        <path
          d="M12.5397 13.3845C12.3231 13.3845 12.1065 13.3031 11.9414 13.1379L9.63634 10.8329C9.30506 10.5016 9.30506 9.96659 9.63634 9.63744C9.96656 9.30615 10.5026 9.30615 10.8328 9.63744L13.1379 11.9415C13.4681 12.2727 13.4681 12.8077 13.1379 13.1379C12.9728 13.3031 12.7562 13.3845 12.5397 13.3845Z"
          fill="#D9002C"
        />
        <path
          d="M9.53024 11.7849C9.31367 11.7849 9.0971 11.7023 8.93199 11.5372C8.60177 11.207 8.60177 10.671 8.93199 10.3407L10.3408 8.93196C10.671 8.60174 11.2071 8.60174 11.5373 8.93196C11.8675 9.26325 11.8675 9.79931 11.5373 10.1285L10.1285 11.5372C9.96231 11.7013 9.74574 11.7849 9.53024 11.7849Z"
          fill="#D9002C"
        />
        <path
          d="M21.7664 22.6113C21.5499 22.6113 21.3344 22.5287 21.1682 22.3636L18.862 20.0585C18.5318 19.7283 18.5318 19.1923 18.862 18.8631C19.1933 18.5318 19.7283 18.5318 20.0596 18.8631L22.3636 21.1682C22.6949 21.4984 22.6949 22.0345 22.3636 22.3636C22.1996 22.5287 21.983 22.6113 21.7664 22.6113Z"
          fill="#D9002C"
        />
        <path
          d="M21.0621 23.3157C20.8455 23.3157 20.629 23.2331 20.4639 23.068C20.1326 22.7378 20.1326 22.2017 20.4639 21.8726L21.8726 20.4638C22.2039 20.1325 22.7389 20.1325 23.0691 20.4638C23.3994 20.794 23.3994 21.3301 23.0681 21.6592L21.6593 23.0691C21.4952 23.2331 21.2787 23.3157 21.0621 23.3157Z"
          fill="#D9002C"
        />
        <path
          d="M11.1063 16.8454H7.84591C7.37846 16.8454 7 16.4659 7 15.9995C7 15.5331 7.37953 15.1536 7.84591 15.1536H11.1063C11.5737 15.1536 11.9522 15.5331 11.9522 15.9995C11.9522 16.4659 11.5737 16.8454 11.1063 16.8454Z"
          fill="#D9002C"
        />
        <path
          d="M7.84591 17.8424C7.37846 17.8424 7 17.4629 7 16.9965V15.0034C7 14.536 7.37846 14.1575 7.84591 14.1575C8.31336 14.1575 8.69182 14.5371 8.69182 15.0034V16.9965C8.69182 17.4629 8.31336 17.8424 7.84591 17.8424Z"
          fill="#D9002C"
        />
        <path
          d="M24.1541 16.8454H20.8938C20.4263 16.8454 20.049 16.4659 20.049 15.9995C20.049 15.5331 20.4274 15.1536 20.8938 15.1536H24.1541C24.6216 15.1536 25.0001 15.5331 25.0001 15.9995C25.0001 16.4659 24.6216 16.8454 24.1541 16.8454Z"
          fill="#D9002C"
        />
        <path
          d="M24.1541 17.8424C23.6866 17.8424 23.3082 17.4629 23.3082 16.9965V15.0034C23.3082 14.536 23.6877 14.1575 24.1541 14.1575C24.6215 14.1575 25 14.5371 25 15.0034V16.9965C25 17.4629 24.6215 17.8424 24.1541 17.8424Z"
          fill="#D9002C"
        />
        <path
          d="M10.2346 22.6113C10.018 22.6113 9.80145 22.5287 9.63634 22.3636C9.30506 22.0334 9.30506 21.4973 9.63634 21.1682L11.9414 18.8631C12.2716 18.5318 12.8066 18.5318 13.1379 18.8631C13.4681 19.1933 13.4681 19.7294 13.1379 20.0585L10.8328 22.3636C10.6677 22.5287 10.4512 22.6113 10.2346 22.6113Z"
          fill="#D9002C"
        />
        <path
          d="M10.939 23.3157C10.7224 23.3157 10.5059 23.232 10.3408 23.068L8.93199 21.6592C8.60177 21.329 8.60177 20.7929 8.93199 20.4627C9.2622 20.1325 9.79827 20.1325 10.1285 20.4627L11.5373 21.8715C11.8675 22.2028 11.8675 22.7388 11.5373 23.068C11.3722 23.232 11.1556 23.3157 10.939 23.3157Z"
          fill="#D9002C"
        />
        <path
          d="M19.4603 13.3845C19.2437 13.3845 19.0271 13.3031 18.862 13.1379C18.5318 12.8067 18.5318 12.2717 18.862 11.9425L21.1682 9.63744C21.4995 9.30615 22.0345 9.30615 22.3636 9.63744C22.6949 9.96766 22.6949 10.5027 22.3636 10.8329L20.0585 13.1379C19.8945 13.3031 19.6779 13.3845 19.4603 13.3845Z"
          fill="#D9002C"
        />
        <path
          d="M22.4709 11.7849C22.2543 11.7849 22.0388 11.7023 21.8726 11.5383L20.4639 10.1285C20.1326 9.79824 20.1326 9.26217 20.4639 8.93196C20.7941 8.60174 21.3301 8.60174 21.6593 8.93196L23.0681 10.3418C23.3993 10.672 23.3993 11.2081 23.0681 11.5383C22.904 11.7013 22.6875 11.7849 22.4709 11.7849Z"
          fill="#D9002C"
        />
        <path
          d="M16.0005 20.8937C18.7036 20.8937 20.8948 18.7025 20.8948 15.9994C20.8948 13.2964 18.7036 11.1052 16.0005 11.1052C13.2975 11.1052 11.1063 13.2964 11.1063 15.9994C11.1063 18.7025 13.2975 20.8937 16.0005 20.8937Z"
          fill="white"
        />
        <path
          d="M16.0006 21.7396C12.8356 21.7396 10.2604 19.1654 10.2604 15.9994C10.2604 12.8345 12.8356 10.2592 16.0006 10.2592C19.1655 10.2592 21.7407 12.8334 21.7407 15.9994C21.7407 19.1654 19.1655 21.7396 16.0006 21.7396ZM16.0006 11.9521C13.7684 11.9521 11.9522 13.7673 11.9522 15.9994C11.9522 18.2316 13.7684 20.0467 16.0006 20.0467C18.2327 20.0467 20.0489 18.2316 20.0489 15.9994C20.0479 13.7673 18.2317 11.9521 16.0006 11.9521Z"
          fill="#D9002C"
        />
      </g>
      <defs>
        <clipPath id="clip0_217_12737">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default InfectionIcon;
