import React from 'react';

const LogoNonUrl: React.FC = () => {
  return (
    <svg
      width="164"
      height="20"
      viewBox="0 0 164 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.34594 0.599323C13.8689 0.599323 17.0569 3.43305 17.5203 7.57476H13.4329C12.9699 5.44943 11.4167 4.05977 9.12789 4.05977C6.02172 4.05977 4.16884 6.62105 4.16884 10.2995C4.16884 13.9508 6.02172 16.5393 9.12789 16.5393C11.4167 16.5393 12.9699 15.1496 13.4329 13.0243H17.5203C17.0569 17.166 13.8689 19.9998 9.34594 19.9998C3.56939 19.9998 0 16.1578 0 10.2995C0 4.44132 3.56939 0.599323 9.34594 0.599323Z"
        fill="white"
      />
      <path
        d="M28.9646 12.8337C28.9646 10.4632 27.6295 8.80105 25.4769 8.80105C23.3244 8.80105 22.0166 10.4632 22.0166 12.8337C22.0166 15.2042 23.3244 16.8664 25.4769 16.8664C27.6295 16.8664 28.9646 15.2042 28.9646 12.8337ZM18.0928 12.8337C18.0928 8.52844 21.0085 5.66744 25.4769 5.66744C29.9456 5.66744 32.8612 8.52844 32.8612 12.8337C32.8612 17.1662 29.9456 19.9999 25.4769 19.9999C21.0085 19.9999 18.0928 17.1389 18.0928 12.8337Z"
        fill="white"
      />
      <path
        d="M35.5593 5.83086L38.9379 15.9126L42.2621 5.83086H46.2949L41.254 19.8363H36.4857L31.4448 5.83086H35.5593Z"
        fill="white"
      />
      <path
        d="M50.8724 19.8362H47.0304V5.83075H50.8724V19.8362ZM51.1721 2.15241C51.1721 3.40585 50.2186 4.3323 48.965 4.3323C47.6844 4.3323 46.7307 3.40585 46.7307 2.15241C46.7307 0.89913 47.6844 -3.43323e-05 48.965 -3.43323e-05C50.2186 -3.43323e-05 51.1721 0.89913 51.1721 2.15241Z"
        fill="white"
      />
      <path
        d="M62.2496 12.8609C62.2496 10.4631 60.942 8.80097 58.8438 8.80097C56.7456 8.80097 55.4106 10.4358 55.4106 12.8336C55.4106 15.2314 56.7456 16.8664 58.8438 16.8664C60.942 16.8664 62.2496 15.2314 62.2496 12.8609ZM66.0371 0.762867V19.8363H62.6857L62.3042 17.9017C61.3778 19.1006 60.0155 19.9998 57.999 19.9998C54.2388 20.0271 51.4869 17.2477 51.4869 12.8063C51.4869 8.47397 54.2388 5.66737 57.9719 5.66737C59.9064 5.66737 61.2959 6.43047 62.1953 7.57481V0.762867H66.0371Z"
        fill="white"
      />
      <path
        d="M79.5056 12.3706L76.9716 5.12272L74.4647 12.3706H79.5056ZM74.6555 0.762955H79.5602L86.4811 19.8365H82.0942L80.6228 15.6676H73.3749L71.9034 19.8365H67.6801L74.6555 0.762955Z"
        fill="#00D474"
      />
      <path
        d="M92.3129 19.9999C87.8988 19.9999 85.0922 17.1389 85.0922 12.8338C85.0922 8.47405 87.9259 5.66761 92.34 5.66761C96.1275 5.66761 98.5525 7.73838 99.0976 11.0626H95.1194C94.7924 9.70037 93.7841 8.80121 92.3129 8.80121C90.2964 8.80121 89.0158 10.4359 89.0158 12.8338C89.0158 15.2043 90.2964 16.8664 92.3129 16.8664C93.8387 16.8664 94.8468 15.9673 95.1467 14.523H99.0976C98.5798 17.9018 96.1275 19.9999 92.3129 19.9999Z"
        fill="#00D474"
      />
      <path
        d="M101.217 9.07354H98.9011V5.83115H101.217V1.90733H105.059V5.83115H108.247V9.07354H105.059V15.2044C105.059 16.1852 105.44 16.594 106.421 16.594H108.41V19.8364H105.222C102.552 19.8364 101.217 18.5013 101.217 15.8309V9.07354Z"
        fill="#00D474"
      />
      <path
        d="M110.863 0.762991H114.568L122.661 12.7792V0.762991H126.693V19.8364H122.96L114.868 7.8201V19.8364H110.863V0.762991Z"
        fill="#00D474"
      />
      <path
        d="M138.329 12.8338C138.329 10.4632 136.994 8.80121 134.841 8.80121C132.689 8.80121 131.381 10.4632 131.381 12.8338C131.381 15.2043 132.689 16.8664 134.841 16.8664C136.994 16.8664 138.329 15.2043 138.329 12.8338ZM127.457 12.8338C127.457 8.52861 130.372 5.66761 134.841 5.66761C139.31 5.66761 142.225 8.52861 142.225 12.8338C142.225 17.1662 139.31 19.9999 134.841 19.9999C130.372 19.9999 127.457 17.1389 127.457 12.8338Z"
        fill="#00D474"
      />
      <path
        d="M145.032 5.83112L147.838 15.6948L150.481 5.83112H153.942L156.503 15.7221L159.364 5.83112H163.288L158.683 19.8365H154.596L152.143 11.3896L149.637 19.8365H145.55L140.972 5.83112H145.032Z"
        fill="#00D474"
      />
    </svg>
  );
};

export default LogoNonUrl;
