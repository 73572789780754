import React from 'react';

const DailyDownloadIcon: React.FC<{ height?: string; width?: string }> = ({
  height = 32,
  width = 40,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M27.1375 26.901C27.1375 28.296 25.9965 29.436 24.6025 29.436H4.32046C2.92646 29.436 1.78546 28.295 1.78546 26.901V11.69C1.78546 10.295 2.92646 9.155 4.32046 9.155H24.6025C25.9975 9.155 27.1375 10.296 27.1375 11.69V26.901Z"
        fill="white"
      />
      <path
        d="M24.6015 30.226H4.32046C2.48746 30.226 0.99646 28.735 0.99646 26.901V11.69C0.99646 9.857 2.48746 8.366 4.32046 8.366H24.6025C26.4345 8.366 27.9265 9.857 27.9265 11.69V26.901C27.9265 28.735 26.4345 30.226 24.6015 30.226ZM4.32046 9.944C3.35746 9.944 2.57446 10.727 2.57446 11.69V26.901C2.57446 27.864 3.35746 28.647 4.32046 28.647H24.6025C25.5655 28.647 26.3485 27.864 26.3485 26.901V11.69C26.3485 10.727 25.5655 9.944 24.6025 9.944H4.32046Z"
        fill="black"
      />
      <path
        d="M14.4605 20.085C14.2875 20.085 14.1135 20.028 13.9685 19.912L2.12146 10.435C1.78146 10.164 1.72646 9.666 1.99846 9.327C2.27046 8.986 2.76746 8.931 3.10746 9.204L14.9535 18.681C15.2935 18.953 15.3495 19.451 15.0765 19.79C14.9215 19.983 14.6915 20.085 14.4605 20.085Z"
        fill="black"
      />
      <path
        d="M14.4615 20.085C14.2305 20.085 14.0015 19.983 13.8455 19.789C13.5735 19.449 13.6285 18.952 13.9685 18.68L25.8155 9.20299C26.1555 8.93099 26.6515 8.98599 26.9245 9.32599C27.1955 9.66599 27.1415 10.163 26.8015 10.434L14.9545 19.912C14.8085 20.028 14.6345 20.085 14.4615 20.085Z"
        fill="black"
      />
      <path
        d="M21.0535 13.719V2.564H32.2075V13.718C32.2075 14.838 31.2995 15.746 30.1805 15.746H21.0535H19.0245V13.718H21.0535V13.719Z"
        fill="#00BFEA"
      />
      <path
        d="M30.1805 16.536H19.0235C18.5875 16.536 18.2345 16.182 18.2345 15.747V13.719C18.2345 13.283 18.5885 12.93 19.0235 12.93H20.2635V2.56399C20.2635 2.12699 20.6175 1.77399 21.0525 1.77399H32.2065C32.6425 1.77399 32.9955 2.12799 32.9955 2.56399V13.718C32.9965 15.272 31.7325 16.536 30.1805 16.536ZM19.8125 14.958H30.1795C30.8615 14.958 31.4175 14.401 31.4175 13.719V3.35299H21.8415V13.719C21.8415 14.155 21.4875 14.508 21.0525 14.508H19.8125V14.958Z"
        fill="black"
      />
      <path
        d="M21.0535 4.59198V13.719C21.0535 14.839 20.1455 15.747 19.0245 15.747C17.9055 15.747 16.9965 14.839 16.9965 13.719V4.59198H21.0535Z"
        fill="#00BFEA"
      />
      <path
        d="M19.0235 16.536C17.4705 16.536 16.2075 15.272 16.2075 13.719V4.59198C16.2075 4.15598 16.5605 3.80298 16.9965 3.80298H21.0535C21.4895 3.80298 21.8425 4.15698 21.8425 4.59198V13.719C21.8425 15.272 20.5785 16.536 19.0235 16.536ZM17.7855 5.38098V13.719C17.7855 14.402 18.3425 14.958 19.0235 14.958C19.7075 14.958 20.2635 14.401 20.2635 13.719V5.38098H17.7855Z"
        fill="black"
      />
      <path
        d="M28.7815 6.77798H24.4765C24.0405 6.77798 23.6895 6.42398 23.6895 5.98898C23.6895 5.55198 24.0415 5.19998 24.4765 5.19998H28.7815C29.2175 5.19998 29.5705 5.55298 29.5705 5.98898C29.5705 6.42498 29.2175 6.77798 28.7815 6.77798Z"
        fill="black"
      />
      <path
        d="M28.7815 12.097H24.4765C24.0405 12.097 23.6895 11.743 23.6895 11.308C23.6895 10.873 24.0415 10.519 24.4765 10.519H28.7815C29.2175 10.519 29.5705 10.873 29.5705 11.308C29.5705 11.743 29.2175 12.097 28.7815 12.097Z"
        fill="black"
      />
      <path
        d="M28.7815 9.43798H24.4765C24.0405 9.43798 23.6895 9.08399 23.6895 8.64899C23.6895 8.21399 24.0415 7.85999 24.4765 7.85999H28.7815C29.2175 7.85999 29.5705 8.21399 29.5705 8.64899C29.5705 9.08399 29.2175 9.43798 28.7815 9.43798Z"
        fill="black"
      />
    </svg>
  );
};

export default DailyDownloadIcon;
